<template>
  <div id="education">
    <h2 class="text-2xl mb-4 font-bold print:text-4xl">Education</h2>

    <h3 class="font-bold text-xl print:text-3xl">Arquitectura Técnica</h3>

    <div class="font-bold">
      <a
        href="https://www.unex.es/?set_language=en&cl=en"
        target="_blank"
        class="link"
      >
        Universidad de Extremadura
      </a>
    </div>

    <time>1998-2002</time>

    <div class="">Cáceres, Extremadura, Spain</div>
  </div>
</template>

<script>
export default {
  name: "EducationContent",
};
</script>
